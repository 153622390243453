<script>
import HeroBar from '@/components/HeroBar.vue'
import TitleBarNew from '@/components/TitleBarNew.vue'

export default {
  name: 'Invoices',
  components: { TitleBarNew, HeroBar },
  data () {
    return {
      titleStack: [
        {
          name: 'Dashboard',
          routeName: 'home',
          params: []
        },
        {
          name: 'Modes',
          routeName: 'shipment.types'
        },
        {
          name: this.$route.params.type,
          routeName: 'shipment.type',
          params: { type: this.$route.params.type }
        },
        {
          name: this.$route.params.year,
          routeName: 'shipment.year',
          params: { year: this.$route.params.year, type: this.$route.params.type }
        },
        {
          name: this.$route.params.invoice
        }
      ],
      shipment: []
    }
  },
  methods: {
    fetch () {
      this.axios.get('/v1/app/shipment/' + this.$route.params.invoice)
        .then(response => {
          this.shipment = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<template>
  <div>
    <title-bar-new :title-stack="titleStack" />
    <hero-bar>
      {{ this.$route.params.invoice }}
    </hero-bar>

    <section class="section is-main-section has-background-white">
      <div class="mb-2 p-4">
         <ul>
           <li>
             <a :href="`https://linctechapp.com/storage/pdf/${$route.params.invoice}.pdf`" target="_blank">Download Invoice</a>
           </li>
           <li v-if="shipment.qr_sticker_name">
             <a :href="`https://linctechapp.com/storage/sticker/${this.shipment.qr_sticker_name}`" target="_blank">Download QR</a>
           </li>
           <li>
             <router-link
               :to="{name:'shipment', params: {id: $route.params.invoice}}"
             >
               View Shipment
             </router-link>
           </li>
         </ul>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
